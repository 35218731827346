import React from 'react';
import BookDemoButtonComponent from '../../BookDemoButton';
import { useTenantContext } from '@/Framework/Tenant/TenantContext';
import useScheduleDemoModal from '../useScheduleDemoModal';
import { variantTypes } from '@/Framework/UI/Atoms/Button';

interface IProps {
  buttonComponent?: React.ElementType,
  title?: string,
  className?: string,
  variantType?: keyof typeof variantTypes,
}

const BookDemoButton = ({ buttonComponent, title, className, variantType }: IProps) => {
  const { tenant } = useTenantContext();

  const {
    isScheduleDemoModalVisible,
    showScheduleDemoModal,
    hideScheduleDemoModal,
    ScheduleDemoModal,
  } = useScheduleDemoModal();

  return (
    <BookDemoButtonComponent
      isScheduleDemoModalVisible={ isScheduleDemoModalVisible }
      showScheduleDemoModal={ showScheduleDemoModal }
      hideScheduleDemoModal={ hideScheduleDemoModal }
      scheduleDemoModal={ ScheduleDemoModal }
      tenant={ tenant }
      buttonComponent={ buttonComponent }
      title={ title }
      className={ className }
      variantType={ variantType as keyof typeof variantTypes }
    />
  );
};

export default BookDemoButton;
